import React from "react";
import Card from "../components/ach-card/card";

function Achievement() {
  return (
    <div className="ach_screen">
      <Card />
    </div>
  );
}

export default Achievement;
