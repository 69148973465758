import React from 'react';
import './main.css';
import Blog from '../components/blogs/blog';

function Blogs() {
  return (
    <div id='blog'>
      <Blog />
    </div>
  );
}

export default Blogs;
