import React from 'react';
import Contact from '../components/contactme/contact';

function contactme() {
  return (
    <div id='contact'>
      <Contact />
    </div>
  );
}

export default contactme;
